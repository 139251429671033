import React, { Fragment, useEffect } from 'react';
import MyRoutes from './router/router';
import axios from "axios";
import { apiConstant } from './components/common/constants/api.constant';

import './App.css';

function App() {

  useEffect(() => {

  }, []);
  return (
    <Fragment>
      <MyRoutes />
    </Fragment>
  );
}

export default App;
