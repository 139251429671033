import React, { useState, useEffect } from "react";
import logoImg from "../../../assets/img/loading.gif";

function Header(props) {
  const [isShow, setIsShow] = useState(false);
  const toggleMenu = () => {
    setIsShow(!isShow);
    if (isShow == false) {
      document.body.className = "openMenu";
    } else {
      document.body.className = document.body.className.replace("openMenu", "");
    }
  };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, []);
  return (
    <div
      id="myHeader"
      className={scroll ? "stickyHeadMenu header_section" : "header_section"}
    >
      <div className="container">
        <div className="row">
          <nav className="navbar navbar-expand-xl navbar-light">
            <a href="/" className="navbar-brand">
              <img width="120" src={(props.headerData) ? props.headerData.logo : logoImg} alt="Logo" />
            </a>
            <button
              aria-controls="basic-navbar-nav"
              type="button"
              aria-label="Toggle navigation"
              className="navbar-toggler collapsed"
              onClick={toggleMenu}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={isShow ? "navbar-collapse" : "navbar-collapse collapse"}
              id="basic-navbar-nav"
            >
              <button className="btn closeMenu" onClick={toggleMenu}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </button>
              <div className="navbar-nav">
                <a href="/" className="nav-link" role="button">
                  Home
                </a>
                {/* <a href="javascript:void()" className="nav-link" role="button">
                  Videos
                </a>
                <a href="javascript:void()" className="nav-link" role="button">
                  Information and Documents
                </a>
                <a href="javascript:void()" className="nav-link" role="button">
                  Conversations and Podcasts
                </a>
                <a href="javascript:void()" className="nav-link" role="button">
                  Credits
                </a> */}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Header;
