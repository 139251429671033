import React, { Fragment, useEffect, useState } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import Select from "react-select";
import { apiConstant } from "../../common/constants/api.constant";

function GallaryListing() {
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const navigate = useNavigate();
  const [arts, setArts] = useState(null);
  const [language, setLanguage] = useState(null);
  const [headerData, setHeaderData] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("Select Language");
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    const headers = {
      'tenant-id': process.env.REACT_APP_TENANT_ID,
      'access-key': process.env.REACT_APP_ACCESS_KEY
    };
    const fetchBranding = () => {
      axios
        .get(process.env.REACT_APP_DOMAIN + apiConstant.branding, { headers: headers })
        .then(function (response) {
          document.title = response.data.data.tenant_name;
          setHeaderData({ logo: response.data.data.brand_image });
          const favicon = document.getElementById("favicon");
          favicon.href = response.data.data.favicon_icon;
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchBranding();
    const fetchExhibitionList = () => {
      axios
        .post(process.env.REACT_APP_DOMAIN + apiConstant.exhibitions_list, {}, { headers: headers })
        .then(function (response) {
          setArts(response.data.data);
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    const fetchLanguageData = () => {
      axios
        .post(process.env.REACT_APP_DOMAIN + apiConstant.languages, {}, { headers: headers })
        .then(function (response) {
          if (response.data.data) {
            let languageOption = [];
            response.data.data.map((item, key) => {
              languageOption.push({ value: item.id, label: item.name });
            });

            setLanguage(languageOption);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchExhibitionList();
    fetchLanguageData();
  }, []);
  useEffect(() => {
    shortString(".short");
  });
  const shortString = (selector) => {
    const elements = document.querySelectorAll(selector);
    const tail = "...";
    if (elements && elements.length) {
      for (const element of elements) {
        let text = element.innerText;
        if (element.hasAttribute("data-limit")) {
          if (text.length > element.dataset.limit) {
            element.innerText = `${text
              .substring(0, element.dataset.limit - tail.length)
              .trim()}${tail}`;
          }
        } else {
          throw Error("Cannot find attribute 'data-limit'");
        }
      }
    }
  };
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, []);
  shortString(".short");
  const navigateToDescription = (id) => {
    navigate(`/detail/${id}`, { state: { id: id } });
  };
  const handleChange = (selectedOption) => {
    setSelectedLanguage(selectedOption.value);
  };
  return (
    <Fragment>
      <Header headerData={headerData} />
      {loader ? (
        <div className="height-75vh-section">
          <div
            className="sweet-loading"
            style={{
              position: "fixed",
              zIndex: "999999999",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
            }}
          ></div>
        </div>
      ) : (
        <section className="content-section">
          <div
            className={scroll ? "sticky-section" : "titleIntro-section"}
            id="stickyHead"
          >
            <div className="container">
              <div className="row">
                <div className="col-12 mt-4 mt-md-0">
                  <h2 className="main_title">Galleries</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="page_section">
            <div className="container">
              <div className="row">
                <div className="desc_data">
                  <p className="description mb-0">Audio Guides/Exhibits</p>
                  <div className="theme_dropdown">
                    {language && (
                      <Select options={language} onChange={handleChange} />
                    )}
                  </div>
                  {/* <select
                    className="custom-select"
                    id="select"
                    placeholder="Select Language"
                    value={selectedLanguage}
                    onChange={handleChange}
                  >
                    <option value="Select Language">Select Language</option>
                    {language &&
                      language.map((item, key) => {
                        return (
                          <option
                            className="short"
                            data-limit="25"
                            key={item.id}
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select> */}
                </div>
              </div>
              <div className="row">
                {arts.length > 0 ? (
                  selectedLanguage && selectedLanguage == "Select Language" ? (
                    arts &&
                    arts.map((item, key) => {
                      let timestamp = new Date(
                        item.created_at.replace(/\s/, "T")
                      );
                      let date = timestamp.getDate();
                      let year = timestamp.getFullYear();
                      let shortMonth = timestamp.toLocaleString("en-us", {
                        month: "short",
                      }); /* Jun */
                      // let finalDate = shortMonth + " " + year;
                      let finalDate = (item.start_date != "" && item.end_date != "") ? (item.start_date + ' - ' + item.end_date) : '';
                      return (
                        <div
                          key={item.id}
                          className="col-12 col-sm-4 col-md-3 mb_15"
                          onClick={() => navigateToDescription(item.id)}
                        >
                          <div className="city_img_bind">
                            <div className="city_title">
                              <h4 className="mb-0">{item.exhibition_name}</h4>
                              <p className="mb-0">{item.creator}</p>
                              <p className="mb-0">{finalDate}</p>
                            </div>
                            <img className="city_img" src={item.cover_image} alt="" />
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    arts &&
                    arts
                      .filter((item) => item.language_id == selectedLanguage)
                      .map((item, key) => {
                        let timestamp = new Date(
                          item.created_at.replace(/\s/, "T")
                        );
                        let date = timestamp.getDate();
                        let year = timestamp.getFullYear();
                        let shortMonth = timestamp.toLocaleString("en-us", {
                          month: "short",
                        }); /* Jun */
                        let finalDate = shortMonth + " " + year;
                        return (
                          <div
                            key={item.id}
                            className="col-12 col-sm-4 col-md-3 mb_15"
                            onClick={() => navigateToDescription(item.id)}
                          >
                            <div className="city_img_bind">
                              <div className="city_title">
                                <h4 className="mb-0">{item.exhibition_name}</h4>
                                <p className="mb-0">{item.creator}</p>
                                <p className="mb-0">{finalDate}</p>
                              </div>
                              <img className="city_img" src={item.cover_image} alt="" />
                            </div>
                          </div>
                        );
                      })
                  )
                ) : (
                  <h1>No Data Available</h1>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
      <Footer />
    </Fragment>
  );
}

export default GallaryListing;
