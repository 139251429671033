import React, { useState, useEffect } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import OwlCarousel, { Options } from "react-owl-carousel";
import axios from "axios";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Select from "react-select";
import { apiConstant } from "../../common/constants/api.constant";


function GallaryIntroduction() {
  const navigate = useNavigate();
  const location = useLocation();
  const [exhibitionDetail, setExhibitionDetail] = useState(null);
  const [artList, setArtList] = useState([]);
  const [exhibitionDate, setExhibitionDate] = useState(null);
  const [loader, setLoader] = useState(true);
  const [period, setPeriod] = useState(null);
  const [collection, setCollection] = useState(null);
  const [accessionNumbers, setAccessionNumbers] = useState(null);
  const [temp, setTemp] = useState(null);
  const [selectedAccessionNumber, setselectedAccessionNumber] =
    useState("Accession No.");
  const [headerData, setHeaderData] = useState(null);
  const params = useParams();

  useEffect(() => {
    if (params != null) {
      const fetchExhibitionDetail = () => {
        const headers = {
          'tenant-id': process.env.REACT_APP_TENANT_ID,
          'access-key': process.env.REACT_APP_ACCESS_KEY
        };
        const fetchBranding = () => {
          axios
            .get(process.env.REACT_APP_DOMAIN + apiConstant.branding, { headers: headers })
            .then(function (response) {
              document.title = response.data.data.tenant_name;
              setHeaderData({ logo: response.data.data.brand_image });
              const favicon = document.getElementById("favicon");
              favicon.href = response.data.data.favicon_icon;
            })
            .catch(function (error) {
              console.log(error);
            });
        };
        fetchBranding();
        axios
          .post(
            process.env.REACT_APP_DOMAIN + apiConstant.exhibition_details,
            {
              exhibition_id: params.id,
            },
            { headers: headers }
          )
          .then(function (response) {
            setExhibitionDetail(response.data.data);
            let timestamp = new Date(
              response.data.data.created_at.replace(/\s/, "T")
            );
            let date = timestamp.getDate();
            let year = timestamp.getFullYear();
            let shortMonth = timestamp.toLocaleString("en-us", {
              month: "short",
            }); /* Jun */
            // let finalDate = shortMonth + " " + year;
            let finalDate = (response.data.data.start_date != "" && response.data.data.end_date != "") ? (response.data.data.start_date + ' - ' + response.data.data.end_date) : '';
            setPeriod(response.data.data.period);
            setCollection(response.data.data.collection);
            setExhibitionDate(finalDate);
            setLoader(false);
          })
          .catch(function (error) {
            console.log(error);
          });
      };
      fetchExhibitionDetail();
      fetchArtList();
      fetchAccessionNumbers();
    }
    else { navigate("/"); }
  }, []);

  const handleAccessionNumbersChange = (selectedOption) => {
    setselectedAccessionNumber(selectedOption.value);
    fetchArtList(params.id, selectedOption.value);
  };
  const headers = {
    'tenant-id': process.env.REACT_APP_TENANT_ID,
    'access-key': process.env.REACT_APP_ACCESS_KEY
  };
  const fetchArtList = (locationId, accession) => {
    axios
      .post(process.env.REACT_APP_DOMAIN + apiConstant.exhibition_tour_point_list, {
        exhibition_id: params.id,
        accession_no: accession,
      }, { headers: headers })
      .then(function (response) {
        setArtList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const fetchAccessionNumbers = () => {
    axios
      .post(
        process.env.REACT_APP_DOMAIN + apiConstant.point_accession_numbers,
        {
          exhibition_id: params.id,
        },
        { headers: headers }
      )
      .then(function (response) {
        if (response.data.data) {
          let accessioNoOption = [];
          response.data.data.map((item, key) => {
            accessioNoOption.push({
              value: item,
              label: `Accession No. ${item}`,
            });
          });
          setAccessionNumbers(accessioNoOption);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const options = {
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      800: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  const navigateToArtifactScreen = (id, artId) => {
    navigate(`/audio-guide/${id}/${artId}`, { state: { id: id, artId: artId } });
  };

  // sticky header effect
  // useEffect(() => {
  //   const header = document.getElementById("stickyHead");
  //   const sticky = header.offsetTop;
  //   const scrollCallBack = window.addEventListener("scroll", () => {
  //     if (window.pageYOffset > sticky) {
  //       header.classList.add("head_sticky");
  //     } else {
  //       header.classList.remove("head_sticky");
  //     }
  //   });
  //   return () => {
  //     window.removeEventListener("scroll", scrollCallBack);
  //   };
  // }, []);

  // componentDidMount = () => {
  //   window.addEventListener("scroll", () => {
  //     let activeClass = "normal";
  //     if (window.scrollY === 0) {
  //       activeClass = "top";
  //     }
  //     this.setState({ activeClass });
  //   });
  // };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, []);

  return (
    <div>
      <Header headerData={headerData} />
      {loader ? (
        <div className="height-75vh-section">
          <div
            className="sweet-loading"
            style={{
              position: "fixed",
              zIndex: "999999999",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
            }}
          ></div>
        </div>
      ) : (
        <section className="content-section">
          <div
            className={scroll ? "sticky-section" : "titleIntro-section"}
            id="stickyHead"
          >
            <div className="container">
              <div className="row">
                <div className="col-12 mt-4 mt-md-0">
                  <button
                    className="btn back_option_page"
                    onClick={() => navigate('/')}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="#7f7f7f"
                      className="bi bi-chevron-left"
                      viewBox="0 0 18 18"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                      />
                    </svg>{" "}
                    Back
                  </button>

                  {exhibitionDetail && (exhibitionDate != null) && (
                    <>
                      <h2 className="main_title remove_margin">
                        {exhibitionDetail.exhibition_name}
                      </h2>
                      <div className="intoduction-data">
                        <p className="description mb-0">
                          {exhibitionDetail.creator}
                        </p>
                        <p>
                          {exhibitionDate} {period && <> {exhibitionDate != "" && <> | </>} {period} </>}
                          {collection && <> {exhibitionDate != "" && <> | </>} {collection}</>}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="coverImage mt-4">
            <div className="container">
              <div className="row">
                {exhibitionDetail && (
                  <div className="col-12">
                    <img src={exhibitionDetail.cover_image} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="page_section">
            <div className="container">
              <div className="row">
                <div className="col-12 mb-4 mt-4 mt-md-0">
                  {exhibitionDetail && (
                    <>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: exhibitionDetail.description,
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-0 mb-md-3">
                  <div className="desc_data">
                    <h5>
                      <strong>Audio Guides</strong>
                    </h5>
                    <div className="theme_dropdown">
                      {accessionNumbers && (
                        <Select
                          options={accessionNumbers}
                          onChange={handleAccessionNumbersChange}
                        />
                      )}
                    </div>
                    {/* { selectedAccessionNumber && <select
                      className="custom-select"
                      placeholder="Accession No."
                      value={selectedAccessionNumber}
                      onChange={handleAccessionNumbersChange}
                    >
                      <option value="Accession No.">Accession No.</option>
                      {
                              accessionNumbers && accessionNumbers.map((item,key)=>{
                                  return <option key={item} value={item}>Accession No. {item}</option>
                              })
                      }
                    </select>} */}
                  </div>
                </div>
                <div className="col-12">
                  {artList.length > 0 ? (
                    <OwlCarousel
                      items={4}
                      className="owl-theme"
                      loop={false}
                      nav
                      dots={false}
                      margin={30}
                      key={Math.random()}
                      {...options}
                    >
                      {selectedAccessionNumber &&
                        selectedAccessionNumber == "Accession No."
                        ? artList &&
                        artList.map((item, index) => {
                          return (
                            <div
                              key={item.id}
                              onClick={() =>
                                navigateToArtifactScreen(
                                  item.exhibition_tour_id,
                                  item.id
                                )
                              }
                            >
                              <div className="city_img_bind">
                                <div className="city_title">
                                  <h4 className="mb-0">{item.name}</h4>
                                  <p className="mb-0">{item.creator}</p>
                                  <p className="mb-0">
                                    Accession No.{item.accession_no}
                                  </p>
                                </div>
                                {/* <div className="icon_setup">
                                                          <span className="video_data">
                                                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-camera-video" viewBox="0 0 16 16">
                                                              <path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"/>
                                                          </svg>
                                                          </span>
                                                          <span className="audio_data">
                                                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-volume-up" viewBox="0 0 16 16">
                                                                  <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"/>
                                                                  <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"/>
                                                                  <path d="M10.025 8a4.486 4.486 0 0 1-1.318 3.182L8 10.475A3.489 3.489 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.486 4.486 0 0 1 10.025 8zM7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12V4zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11z"/>
                                                              </svg>
                                                          </span>
                                                      </div> */}
                                <img
                                  className="city_img"
                                  src={item.cover_image}
                                  alt=""
                                />
                              </div>
                            </div>
                          );
                        })
                        : artList &&
                        artList
                          .filter(
                            (item) =>
                              item.accession_no == selectedAccessionNumber
                          )
                          .map((item, index) => {
                            return (
                              <div
                                key={item.id}
                                onClick={() =>
                                  navigateToArtifactScreen(
                                    item.exhibition_tour_id,
                                    item.id
                                  )
                                }
                              >
                                <div className="city_img_bind">
                                  <div className="city_title">
                                    <h4 className="mb-0">{item.name}</h4>
                                    <p className="mb-0">{item.creator}</p>
                                    <p className="mb-0">
                                      Accession No.{item.accession_no}
                                    </p>
                                  </div>
                                  {/* <div className="icon_setup">
                                                          <span className="video_data">
                                                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-camera-video" viewBox="0 0 16 16">
                                                              <path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"/>
                                                          </svg>
                                                          </span>
                                                          <span className="audio_data">
                                                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-volume-up" viewBox="0 0 16 16">
                                                                  <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"/>
                                                                  <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"/>
                                                                  <path d="M10.025 8a4.486 4.486 0 0 1-1.318 3.182L8 10.475A3.489 3.489 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.486 4.486 0 0 1 10.025 8zM7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12V4zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11z"/>
                                                              </svg>
                                                          </span>
                                                      </div> */}
                                  <img
                                    className="city_img"
                                    src={item.cover_image}
                                    alt=""
                                  />
                                </div>
                              </div>
                            );
                          })}
                    </OwlCarousel>
                  ) : (
                    <h1>No Data Available</h1>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <Footer />
    </div>
  );
}

export default GallaryIntroduction;
