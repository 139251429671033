import React from 'react'

function Footer() {
    return (
        <footer>
            <section className="copyright_text">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <p className="mini-text mb-0 white_text">© {(new Date()).getFullYear()} by {process.env.REACT_APP_NAME}</p>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    )
}

export default Footer
