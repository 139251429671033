import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import GallaryListing from '../components/pages/GallaryListing/index';
import GallaryIntroduction from '../components/pages/GallaryIntroduction/index';
import ArtifactsAudioGuides from '../components/pages/ArtifactsAudioGuides/index';

function MyRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/detail/:id" element={<GallaryIntroduction />} />
        <Route path="/audio-guide/:id/:artId" element={<ArtifactsAudioGuides />} />
        <Route path="/" element={<GallaryListing />} />
        <Route exact path="/" element={<Navigate to="/" />} />
        <Route path='*' element={<Navigate to="/" />} />
      </Routes>
    </Router>
  )
}

export default MyRoutes;